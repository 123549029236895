import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "LifeTech - meetup for engineer を開催しました",
  "date": "2016-04-06T01:39:31.000Z",
  "slug": "entry/2016/04/06/103931",
  "tags": ["medley"],
  "hero": "./2016_04_06.png",
  "heroAlt": "lifetech"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`メドレー広報の阿部です。`}</p>
    <p>{`医療ヘルスケア分野を IT の力で変えていこうとする企業（メドレー ×FiNC× エムスリー）が集まり企画した「LifeTech- meetup for engineer」の第 1 回を FiNC 本社で開催しました。そのレポートをお届けします！`}</p>
    <p>{`業界のエンジニア同士でノウハウ共有の機会をつくるとともに、医療ヘルスケアにおけるエンジニアの可能性を広く知ってほしいという 3 社の思いから生まれた本イベント。`}</p>
    <p>{`50 人定員に対し、応募開始数日で 100 人の申し込みをいただいたほか、実施後のアンケートは「イベントに満足」という回答率が 100％となり、多くの方に医療ヘルスケア ×IT の魅力を伝えられる機会となったのではと、事務局として嬉しく感じています。`}</p>
    <p>{`当日は各社 3〜4 名の CTO ・エンジニアが LT を行いました。`}</p>
    <p>{`弊社からは、取締役 CTO の平山がトップバッターとして登壇。日本が抱える医療課題を示しながら、メドレーが世の中に提供していく価値をお伝えしたほか、プロダクトや開発チームの特徴を紹介しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160404/20160404151828.jpg",
        "alt": "f:id:medley_inc:20160404151828j:plain"
      }}></img></p>
    <p>{`その後、開発部の平木より「オンライン通院システム『`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS（クリニクス）`}</a>{`』の舞台裏」、石井より「オンライン病気事典『`}<a parentName="p" {...{
        "href": "https://www.medley.life/"
      }}>{`MEDLEY`}</a>{`』がこだわる Performance & Automation」と題し、各プロダクトの技術選定や開発のこだわりなどをお話しました。`}</p>
    <h2>{`資料はこちらから`}</h2>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/onraintong-yuan-sisutemu-clinics-kurinikusu-falsewu-tai-li"
      }}>{`オンライン通院システム「CLINICS（クリニクス）」の舞台裏 // Speaker Deck`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/onrainbing-qi-shi-dian-medley-gakodawaruperformance-and-automation"
      }}>{`オンライン病気事典「MEDLEY」がこだわる Performance & Automation // Speaker Deck`}</a></p>
    <h2>{`エンジニア type さんでも紹介いただきました！`}</h2>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="盛り上がる「LifeTech」普及のカギは安全性と使い勝手の融合～FiNC×エムスリー×メドレーの 3 社に聞く - エンジニア type | 転職＠type" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Ftype.jp%2Fet%2Ffeature%2F1015" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://type.jp/et/feature/1015">type.jp</a></cite>
    <p>{`その後はお楽しみ、懇親会！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160404/20160404153902.jpg",
        "alt": "f:id:medley_inc:20160404153902j:plain"
      }}></img></p>
    <p>{`参加者のほぼ全員が懇親会にも出席してくださり、会場はぎゅうぎゅうに…！`}</p>
    <p>{`登壇者と参加者の交流も弾み、「よい出会いがありました！」と言ってお帰りいただく方もいるなど、さまざまな情報交換をできる有意義な場となったようです。`}</p>
    <p>{`引き続き「LifeTech - meetup for engineer」は定期開催を予定しています＾＾`}</p>
    <p>{`医療ヘルスケアの業界を盛り上げるべく、さまざまな情報交換ができる場にしていきたいと思っておりますので、今回抽選に漏れてしまった方も、興味が湧いた方も、ご参加お待ちしております！`}</p>
    <p>{`※イベント情報はブログや`}<a parentName="p" {...{
        "href": "https://www.facebook.com/medley.jp/"
      }}>{`Facebook`}</a>{`、`}<a parentName="p" {...{
        "href": "https://lifetech.connpass.com/"
      }}>{`connpass 上のグループ`}</a>{`で発信してまいります。ぜひチェックしてみてください！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      